@font-face {
  font-family: spectral-bold;
  src: url(../src/fonts/Spectral-Bold.ttf);
}

@font-face {
  font-family: cedar-regular;
  src: url(../src/fonts/CedarvilleCursive-Regular.ttf);
}

@font-face {
  font-family: roboto;
  src: url(../src/fonts/Roboto-Medium.ttf);
}

* {
  scroll-behavior: smooth;
}

.banerBg {
  background-color: #1d1d1d;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

a {
  text-decoration: none !important;
}

.freespace {
  height: 150px;
}

/* 1st */
.nav-link1 p {
  color: #6f4bff;
  text-align: right;
  margin-bottom: 0;
  font-size: 10px;
  font-family: roboto, sans-serif;
}

.nav-link1 {
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  flex-direction: column;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-link1:hover {
  color: #6f4bff;
}

/* 2nd */
.nav-link2 p {
  color: #6f4bff;
  text-align: right;
  margin-bottom: 0;
  font-size: 10px;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-link2 {
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  flex-direction: column;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-link2:hover {
  color: #6f4bff;
}

/* 3rd */
.nav-link3 p {
  color: #6f4bff;
  text-align: right;
  margin-bottom: 0;
  font-size: 10px;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-link3 {
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  flex-direction: column;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-link3:hover {
  color: #6f4bff;
}

/* 4th  */
.nav-link4 p {
  color: #6f4bff;
  text-align: right;
  margin-bottom: 0;
  font-size: 10px;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-link4 {
  color: #fff;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  flex-direction: column;
}

.nav-link4:hover {
  color: #6f4bff;
}

.lottie {
  border-radius: 50%;
}

.wholeBg {
  background-color: #1d1d1d;
}

.loader-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  height: 1px;
  width: 80%;
  background-color: #05fdd8;
  margin-left: 10px;
}

.aboutmeText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutmeText h1 {
  color: #fff;
  font-family: spectral-bold, sans-serif;
  /* font-size: 20px; */
  margin-bottom: 0 !important;
}

.aboutmeText span {
  color: #05fdd8;
  font-size: 30px;
}

.mypara {
  color: #fff;
  font-size: 18px;
  /* font-family: spectral-bold, sans-serif; */
}

.mypara span {
  font-weight: 700;
  font-size: 20px;
}

.commands p {
  color: #fff;
  font-family: spectral-bold, sans-serif;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 3%;
}

.info-icon-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  /* border-bottom: #fff solid 1px; */
}

.info-icon {
  width: 24px;
  cursor: pointer;
  transition: all ease 1s;
}

.info-span {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}

.social-icon {
  width: 24px;
  margin-top: 30px;
  cursor: pointer;
  transition: all ease 1s;
}

.social-icon:hover {
  transform: scale(1.2);
}

.verticle-line {
  width: 1px;
  height: 60px;
  background-color: #05fdd8;
  margin-top: 30px;
}

.e-mail p {
  color: #fff;
  rotate: 90deg;
  margin-bottom: 100px;
  cursor: pointer;
  transition: all ease 0.1s;
  font-size: 14px;
}

.e-mail p:hover {
  /* color: #FD2155; */
  transform: scale(1.05);
}

.e-mail {
  position: fixed;
  bottom: 0;
  right: -1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.language {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@keyframes typing {
  0% {
    width: 0;
  }

  50% {
    width: 0;
  }

  100% {
    width: 100;
  }
}

@keyframes intro {
  0% {
    width: 0;
  }

  50% {
    width: 0;
  }

  100% {
    width: 100;
  }
}

@keyframes sd {
  0% {
    width: 0;
  }

  50% {
    width: 0;
  }

  100% {
    width: 100;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.hi {
  display: flex;
  align-items: center;
  width: 100%;
  animation: typing 2s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.himobile {
  display: flex;
  align-items: center;
  width: 100%;
  animation: typing 2s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.mobileText {
  display: none;
}

@media (max-width: 600px) {
  .hi {
    display: none;
  }

  .intro {
    display: none !important;
  }

  .softwaredeveloper {
    display: none !important;
  }

  .mobileText {
    display: block !important;
  }
}

.col {
  padding-left: 0px;
}

.awaisbhiintro {
  display: flex;
  align-items: center;
}

.awaisBhiImg {
  background-color: #000;
  background-image: url(../src/images/image1.png);
  width: 100%;
  height: 320px;
  background-position: center;
  background-size: contain;
  /* transition: all ease 1s; */
}

/* .asquare {
  display: flex;
  align-items: center;
  justify-content: right;
} */

.inspire {
  display: flex;
  align-items: center;
  animation: intro 4.5s steps(32, end), blink 0.5s step-end infinite alternate;
  transition: all ease 1s;
  white-space: nowrap;
  overflow: hidden;
}

.intro {
  display: flex;
  align-items: center;
  width: 100%;
  animation: intro 2.5s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 1s;
}

.intromobile {
  display: flex;
  align-items: center;
  width: 100%;
  animation: intro 2.5s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 1s;
}

.softwaredeveloper {
  display: flex;
  align-items: center;
  width: 100%;
  animation: sd 4s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.draftsman {
  display: flex;
  align-items: center;
  width: 100%;
  animation: sd 7s steps(38, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.visualizer {
  display: flex;
  align-items: center;
  width: 100%;
  animation: sd 11.5s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.softwaredevelopermobile {
  display: flex;
  align-items: center;
  width: 100%;
  animation: sd 4s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.softwaredevelopermobile1 {
  display: flex;
  align-items: center;
  width: 100%;
  animation: sd 7s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.softwaredevelopermobile2 {
  display: flex;
  align-items: center;
  width: 100%;
  animation: sd 12s steps(32, end), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  transition: all ease 51s;
}

.mobile-icons {
  display: none !important;
}

.mobile-e-mail {
  display: none;
}

.Had {
  color: #fff;
  font-size: 40px;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  font-family: spectral-bold, sans-serif;
  margin-bottom: 0px;
}

.Had:hover {
  animation-name: rubberBand;
  color: aqua;
}

.navbarPosition {
  position: fixed;
  width: 100%;
  z-index: 10000;
  background-color: #1d1d1d;
  padding-bottom: 10px;
}

.Hmobile {
  color: #fff;
  font-size: 60px;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  font-family: spectral-bold, sans-serif;
  margin-bottom: 0px;
}

.Hmobile:hover {
  animation-name: rubberBand;
  color: aqua;
}

.Hintromobile {
  color: #fff;
  font-size: 42px;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  font-family: spectral-bold, sans-serif;
  margin-bottom: 0px;
}

.Hintromobile:hover {
  animation-name: rubberBand;
  color: aqua;
}

.layout {
  color: #515152;
  font-family: cedar-regular, sans-serif !important;
  text-decoration: wavy;
  font-size: 23px;
}

.frontend {
  color: #8d8d8d;
  font-size: 25px;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1, 0.75, 0.5);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

.A {
  color: #fd2155;
  text-shadow: -4px 0px #05fdd8;
  font-size: 70px;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  font-family: spectral-bold, sans-serif;
  margin-bottom: 0px;
}

.skillsBg {
  background-color: #1d1d1d;
}

.Upstatement {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.Upstatement:hover {
  background-color: #8d8d8d;
}

.selected {
  background-color: #8d8d8d;
  /* color: #fff; */
  border-left: 2px solid aqua;
  cursor: pointer;
}

.selected h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: aqua;
}

.Upstatement h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Scout {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.Scout:hover {
  background-color: #8d8d8d;
}

.Scout h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Apple {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.Apple:hover {
  background-color: #8d8d8d;
}

.Apple h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.empire {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.empire:hover {
  background-color: #8d8d8d;
}

.empire h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Starry {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.Starry:hover {
  background-color: #8d8d8d;
}

.Starry h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Mullen {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.Mullen:hover {
  background-color: #8d8d8d;
}

.Mullen h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.finalyear {
  color: #fff;
  border-left: 2px solid aqua;
  cursor: pointer;
}

.finalyear:hover {
  background-color: #8d8d8d;
}

.finalyear h3 {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.heading {
  color: aqua;
  font-family: spectral-bold, sans-serif !important;
}

.dates {
  color: #fff;
}

.paraone {
  color: #fff;
}

.paratwo {
  color: #fff;
}

.parathree {
  color: #fff;
}

.parafour {
  color: #fff;
}

.parafive {
  color: #fff;
}

.parasix {
  color: #fff;
}

.places {
  border-left: #fd2155;
}

.blog-image {
  transition: all ease-in-out 1s;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-image:hover {
  transform: scale(1.1);
  width: 100%;
}

.image-setting {
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tag {
  text-transform: uppercase;
  background-color: #f5f7fd;
  border: 1px solid #dfe7fb;
  font-size: 14px;
  display: inline-block;
  padding: 4px 8px;
}

.projects {
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.textandarrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textandarrow p {
  margin-bottom: 0;
  margin-right: 20px;
}

.projects h4 {
  font-size: 19px;
  font-family: spectral-bold, sans-serif;
}

.projects p {
  font-family: spectral-bold, sans-serif;
}

.single-project:hover .blog-image {
  transform: scale(1.1);
}

.single-project {
  transition: all ease 1s;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 8px 40px rgba(12, 22, 44, 0.32);
}

.svgArrow {
  transition: all ease 1s;
}

.single-project:hover .projects {
  background-color: #fff;
  color: #000;
}

.single-project:hover .svgArrow {
  transform: translateX(40%);
}

.folderandlink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Ourprojects {
  background-color: #8d8d8d;
  padding: 15px 30px;
  position: relative;
  top: 0;
  transition: top ease 0.1s;
  border-radius: 20px;
}

.Ourprojects:hover {
  /* box-shadow: 1px 1px 10px 1px #05FDD8; */
  top: -10px;
}

.ourprojects h1 {
  color: #fff;
  font-family: spectral-bold, sans-serif;
}

.ourprojects {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectHeading {
  font-family: spectral-bold, sans-serif;
}

.singleProject {
  transition: all ease 1s;
}

.singleProject:hover .projectHeading {
  color: aqua;
}

.languages {
  display: flex;
  align-items: center;
  justify-content: start;
}

.languages {
  font-family: spectral-bold, sans-serif;
}

.digitalexp h2 {
  color: #c4d0ec;
  font-size: 22px;
  font-family: spectral-bold, sans-serif;
}

.star {
  width: 17px;
  margin-right: 10px;
}

.sayHelloBtn {
  padding-top: 15px;
  color: #fff;
  font-family: spectral-bold, sans-serif;
}

.sayHelloBtn:hover {
  background-color: #04362f;
}

.sayHelloBtn p {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.sayHello {
  border: 2px solid #05fdd8;
  width: 150px;
}

.getintouch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footerHeading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.getintouch h1 {
  color: #fff;
  font-family: spectral-bold, sans-serif;
}

.getintouch p {
  color: #fff;
}

.howiworked {
  height: 350px;
}

.walletBtn p {
  color: #fff;
  border: 3px solid #6f4bff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0 !important;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
  border-radius: 30px;
}

.walletBtn {
  cursor: pointer;
}

.walletBtn:hover {
  background-color: #6f4bff;
  border-radius: 30px;
}

.walletBtnscrolled {
  background-color: #6f4bff;
  border-radius: 30px;
  cursor: pointer;
}

.walletBtnscrolled p {
  color: #fff;
  border: 3px solid #6f4bff;
  border-radius: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0 !important;
  font-family: roboto, sans-serif;
  font-weight: 500 !important;
}

.nav-item a {
  font-size: 16px;
  font-weight: bold;
  margin: 0px 18px;
}

.v-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* .mx-auto {
  margin-right: 0 !important;
} */

.logo {
  color: #fff;
}

.learnMore {
  display: flex;
  align-items: center;
}

.learnMore p {
  margin-bottom: 0;
  color: #ffff;
  font-family: roboto, sans-serif;
  font-size: 18px;
}

@media (max-width: 600px) {
  .icons {
    display: none;
  }

  .navbarPosition {
    position: relative;
  }

  .awaisbhiintro {
    padding: 0 !important;
  }

  .mobileres {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .expert {
    color: #f0f0f0;
    font-size: 27px !important;
    /* font-weight: 700; */
    /* font-family: roboto; */
  }

  .networks {
    color: #6f4bff;
    font-size: 40px !important;
    font-weight: 700;
    font-family: roboto;
  }

  .mobilebanner {
    padding-left: 20px !important;
  }

  .getInTouchBtn {
    width: 100% !important;
  }

  .planBg {
    margin-top: 20px !important;
  }

  /* .singlePortfolio {
    margin-top: 20px !important;
    padding: 20px !important;
  } */

  .mobileGroup {
    padding-top: 0 !important;
  }
  .nameandDes h1 {
    font-size: 20px !important;
  }

  .nameandDes p {
    font-size: 12px !important;
  }
  .starterpack p {
    padding: 6px 10px !important;
  }

  .popular {
    padding: 6px 10px !important;
  }

  .iframe {
    height: 120px !important;
  }

  .draftsman {
    display: none;
  }

  .filterSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
  }

  .howiworked {
    height: 100%;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .v-center {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .selected h3 {
    padding-right: 20px;
    font-size: 17px;
    width: 150px;
  }

  .selected {
    border-left: 0px solid;
  }

  .nav-item a {
    margin: 0;
    display: flex;
    align-items: start;
    padding-top: 10px;
  }

  .mobile-icons {
    display: block !important;
  }

  .mobile-e-mail p {
    color: #fff;
    cursor: pointer;
    display: block;
  }

  .mobile-e-mail {
    display: block;
  }

  .aboutmeText {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .aboutmeText h1 {
    font-size: 27px;
  }

  .all {
    margin-bottom: 20px;
    margin-left: 0 !important;
  }

  .data {
    margin-bottom: 20px;
    margin-left: 0 !important;
  }

  .web {
    margin-bottom: 20px;
  }

  .freespace {
    height: 0 !important;
  }

  .banerBg {
    height: 100% !important;
  }

  .allwhenPresed {
    margin-bottom: 20px;
  }

  .whenotherClicked {
    margin-bottom: 20px;
  }

  .forDesktop {
    display: none;
  }

  .forMobile {
    display: block !important;
  }
}

.forMobile {
  display: none;
}

.logoaa {
  display: flex;
  align-items: center;
  justify-content: center;
}

.folderLink {
  width: 35px;
}

.linkIcon {
  width: 35px;
}

.arrowDown {
  /* background-color: #000; */
  width: 70px;
}

.filterSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  color: #fff;
}

.filterSection p {
  margin-bottom: 0;
}

.all {
  color: #fff;
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 20px;
}

.allwhenPresed {
  color: #05fdd8;
  border: 1px solid transparent;
  background-color: transparent;
  border: 1px solid #05fdd8;
  border-radius: 5px;
  font-size: 20px;
}

.all:focus {
  color: #05fdd8;
  border: 1px solid transparent;
}

.data {
  color: #fff;
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 20px;
}

.data:hover {
  color: #05fdd8;
}

.whenotherClicked {
  color: #05fdd8;
  border: 1px solid #05fdd8;
  background-color: transparent;
  border-radius: 5px;
  font-size: 20px;
}

.web {
  color: #fff;
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 20px;
}

.web:active {
  color: #05fdd8;
  border: 0px solid transparent;
}

.web:focus {
  color: #05fdd8;
  border: 0px solid transparent;
}

.caseStudieBg {
  background-color: #000;
}

.investate {
  display: flex;
  align-items: left;
  justify-content: flex-start;
}

.sombineState p {
  color: #fff;
  font-size: 13px;
}

.investate h1 {
  color: #fff;
  font-family: spectral-bold, sans-serif;
  font-size: 70px;
}

hr {
  background-color: #fff;
  width: 100%;
}

.overView {
  color: #fff;
  font-weight: 600;
}

.platform h1 {
  color: #fff;
  font-family: spectral-bold, sans-serif;
}

.realState {
  color: #fff;
  font-family: spectral-bold, sans-serif;
}

.placeholder {
  background-color: #05fdd8;
  position: absolute;
  top: 0;
  width: 95%;
  height: 290px;
  border-radius: 30px;
}

.name {
  color: #f0f0f0;
  font-size: 18px;
  font-weight: 500;
  font-family: roboto;
}

.expert {
  color: #f0f0f0;
  font-size: 35px;
  font-weight: 900;
  /* font-family: roboto; */
}

.networks {
  color: #6f4bff;
  font-size: 60px;
  font-weight: 700;
  font-family: roboto;
  text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.ipsum {
  color: #e1e1e1;
  font-size: 22px;
  font-weight: 400;
  font-family: roboto;
}

.getInTouchBtn {
  width: 220px;
  height: 35;
  background-color: #6f4bff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding: 16px 45px;
  color: #ffff;
}

.getInTouchBtn p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  font-family: roboto;
}

.plan {
  color: #fff;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lorem {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.planBg {
  background: #3e3e3e;
  /* padding: 32px; */
  border-radius: 30px;
  overflow: hidden;
}

.starterpack {
  /* border-top-left-radius: 28.737px; */
  /* border-top-right-radius: 28.737px; */
  background: #6f4bff;
  width: 100%;
  padding: 20px 0;
}
.placeholderGroup {
  padding-left: 25px;
  padding-right: 25px;
}

.starterpack p {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
  margin-bottom: 0;
  padding: 5px 0;
}

.dummyDes {
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 23.2px */
}

.placeholderText {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.priceGroup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.priceGroup p {
  color: #d2d2d2;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.priceGroup h1 {
  color: #fff;
  font-family: Roboto;
  font-size: 38.316px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.choosePlan {
  display: flex;
  /* padding: 16px 64px; */
  justify-content: center;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-radius: 40px;
  background: #6f4bff;
  width: 194px;
  margin: auto;
  margin-bottom: 20px;
}

.choosePlan p {
  margin-bottom: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.popular {
  border-radius: 28.737px;
  background: #c9fa60;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
  gap: 10.643px;
  width: 140px;
  margin-left: 10px;
}

.popular p {
  margin-bottom: 0;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.buttons {
  display: flex;
  align-items: center;
}

.Ha {
  color: #fff;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.singlePortfolio {
  padding: 0px;
  background-color: #3e3e3e;
  border-radius: 30px;
  overflow: hidden;
}

.nameandDes {
  padding: 32px;
}

.nameandDes h1 {
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
}

.nameandDes p {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.w-full {
  min-width: 100%;
}
.sliderbg {
  background-image: url(./images/sliderbg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}
